<template>
  <div class="content-wrap">
    <div class="cont-oprt">
      <el-button
        type="primary"
        @click="handleCheckTrade"
        v-hasPermi="['coo:trade:check']"
        >合规检查</el-button
      >
      <el-button
        type="primary"
        v-if="cooRole == 'DS'"
        @click="handleUpdateTrade"
        v-hasPermi="['coo:trade:update']"
        >修改单据</el-button
      >
      <el-button
        type="default"
        @click="handleRedirectToInvoicing"
        v-hasPermi="['coo:trade-invoicing:detail']"
        >开票详情</el-button
      >
      <el-button
        type="default"
        @click="handleRedirectToReceiving"
        v-hasPermi="['coo:trade-receiving:detail']"
        >交票详情</el-button
      >
      <el-button
        type="default"
        @click="handleRedirectToAuditPage"
        v-hasPermi="['coo:trade-matching:detail']"
        >稽核详情</el-button
      >
      <router-link
        v-if="cooRole == 'DS'"
        target="_blank"
        :to="{ path: '/share-settlement-verify-pwd' }"
        v-hasPermi="['coo:trade:share']"
      >
        <el-button type="primary" style="margin: 0px 10px">邀请协同</el-button>
      </router-link>
      <el-button
        type="primary"
        icon="el-icon-refresh"
        circle
        class="circle"
        title="刷新订单"
        @click="handleQueryTrade"
        v-hasPermi="['coo:trade:detail']"
      ></el-button>
    </div>
    <!-- 结算订单详情 -->
    <trade-basic
      scene="XQ"
      :coo-role="cooRole"
      :settlement="settlement"
      v-loading="settlementLoading"
    ></trade-basic>

    <!-- 订单原始明细 -->
    <trade-item-list
      :id="id"
      :cooRole="cooRole"
      ref="tradeItemListRef"
    ></trade-item-list>

    <!-- 订单合规校验 -->
    <check-trade ref="checkTradeRef"></check-trade>

    <!-- 修改结算订单 -->
    <update-trade
      ref="updateTradeRef"
      @refreshPage="handleQueryTrade"
    ></update-trade>
  </div>
</template>

<script>
import UpdateTrade from "@/views/cooperate/settlement/components/updateTrade.vue";
import CheckTrade from "@/views/cooperate/settlement/components/checkTrade.vue";
import TradeBasic from "@/views/cooperate/settlement/components/tradeBasic.vue";
import TradeItemList from "@/views/cooperate/settlement/components/item/tradeItemList.vue";
import { reqSelectSettlement } from "@/service/coo/trade.js";

export default {
  name: "SettlementTradeDetail",
  components: {
    UpdateTrade,
    CheckTrade,
    TradeBasic,
    TradeItemList,
  },
  props: {
    cooRole: {
      type: String,
      default: "SS",
    },
  },
  data() {
    return {
      id: this.$route.query.id,
      settlement: {},
      settlementLoading: false,
    };
  },
  created() {
    this.$watch(
      () => this.$route.path,
      async () => {
        this.queryTrade();
      }
    );

    this.queryTrade();
  },
  methods: {
    // 转到收票详情
    handleRedirectToReceiving() {
      this.$router.push({
        path: `/coo/${
          this.cooRole == "DS" ? "buy" : "sal"
        }/trade/receiving/detail`,
        query: { id: this.id },
      });
    },
    // 转到开票详情
    handleRedirectToInvoicing() {
      this.$router.push({
        path: `/coo/${
          this.cooRole == "DS" ? "buy" : "sal"
        }/trade/invoicing/detail`,
        query: { id: this.id },
      });
    },
    // 转到稽核详情
    handleRedirectToAuditPage() {
      this.$router.push({
        path: `/coo/${
          this.cooRole == "DS" ? "buy" : "sal"
        }/trade/matching/detail`,
        query: { id: this.id },
      });
    },
    // 重新查询订单
    handleQueryTrade() {
      this.queryTrade();
      this.$refs.tradeItemListRef.queryItems();
    },
    // 订单合规校验
    handleCheckTrade() {
      this.$refs.checkTradeRef.showDialog([this.settlement]);
    },
    // 修改结算订单
    handleUpdateTrade() {
      this.$refs.updateTradeRef.showDialog();
    },
    // 查询订单详情
    async queryTrade() {
      this.settlementLoading = true;
      const { success, data } = await reqSelectSettlement(this.id);
      if (success) {
        this.settlement = data;
      }
      this.settlementLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.cont-oprt {
  display: flex;
  padding: 10px 20px;
  justify-content: flex-end;
  background-color: #ffffff;

  .el-button {
    padding: 0px 10px;
  }

  .circle {
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
